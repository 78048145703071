* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif, "Lato", sans-serif, "Open Sans",
    sans-serif;
}

body {
  margin: 0;
  overflow-x: hidden !important;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dropdown-toggle::after {
  display: none !important;
}

.nav-link {
  color: #000000 !important;
}

.nav-link:hover {
  color: #101010 !important;
}

.menu-font {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000 !important;
  padding: 26px 56px;
  box-shadow: 0 0 3px #dfdfdf;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 60px 30px;
}

.product-list-item {
  display: flex;
  flex: 1 0 30%;
  flex-direction: column;
  color: #000;
  background-color: #fff;
  border-radius: 30px;
  padding: 30px;
  cursor: pointer;
}

div.dropdown-menu.show {
  background-color: #000000;
}

a.dropdown-item {
  color: #ffffff;
}

a.dropdown-item:hover {
  color: #000 !important;
  background-color: #eeeef0 !important;
}

.culture-card {
  margin-left: 39px;
}

.footer-card {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
}

.bg1-white {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 55px;
  padding: 10px 80px;
  width: 70%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-action-app-link {
  width: auto;
  height: 90px;
}

.bg1-white-black {
  margin-top: 100px;
  height: 166px;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, #000 50%, #000 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-media-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-container {
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
  display: flex;
}

.footer-container {
  width: 100%;
  display: flex;
  padding: 26px 56px;
}

.content-container {
  padding: 0 112px;
  margin: 0;
  width: 100%;
}

.banner-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  color: #914082;
}

.home-product {
  display: flex;
}

.home-product-holder {
  padding-top: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.home-product-molecule {
  display: flex;
  flex-direction: row;
  background-color: #efefef;
}

.home-product-molecule-content {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.home-product-molecule-image {
  display: flex;
}

.home-product-split {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.home-product-bio-sample {
  flex: 1;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
}

.home-product-bio-sample-content {
  display: flex;
  align-content: center;
}

.service-holder {
  display: flex;
  flex-direction: row;
  padding: 80px;
  gap: 40px;
}

.form-holder {
  display: flex;
  flex-direction: row;
  padding: 60px;
  background: url("/public/images/canva/72.png") bottom right no-repeat;
  background-size: 300px 300px;
}

.contact-form {
  flex: 1;
  padding: 40px;
  background: url("/public/images/logo/logo.png") left top no-repeat, url("/public/images/canva/71.png") left top no-repeat;
}

.contact-form-content {
  flex: 1;
  padding: 80px;
}

.footer-content {
  display: flex;
  flex-direction: row;
}

.home-product-diabetes {
  flex: 1;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
}

.training-holder {
  padding: 40px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.training-item {
  flex: 1 0 20%;
  padding: 40px;
  background-color: #eeeef0;
  color: #914082;
}

.home-product-diabetes-content {
  display: flex;
}

.home-product .title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
}

.product-key-list ul {
  list-style: none;
  padding: 0;
}

/*.product-key-list ul>li {
  position: relative;
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}*/

.kriya-product-key-list {
  display: flex;
  flex-direction: row;
  background-color: #fefefe;
}

.kriya-pro-split {
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
}

.kriya-pro-split-2 {
  display: flex;
  flex: 2;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
}


.service-card {
  background-color: #efefef;
}

/*.product-key-list ul>li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-image: url('images/canva/tick-circle.png');
  background-size: contain;
  background-repeat: no-repeat;
}*/

.phlebo-back {
  background-image: url('/public/images/canva/90.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 200px;
}

.content-description {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.content-align-vcenter {
  display: flex;
  align-content: center;
}

.digital-prod-holder {
  display: flex;
  align-content: center;
  gap: 40px;
  padding: 120px 80px;
}

.blog-holder {
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 40px;
}

.blog-holder-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  color: #000;
  padding: 20px;
  gap: 10px;
}

.one-click-button {
  background-color: #914082;
  padding: 10px 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.banner-description {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1f1f1f;
}

.button-holder {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.banner-button-holder {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 300px;
  height: 48px;
}

.banner-button-invert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 10px;

  width: 140px;
  height: 48px;

  color: #FFFFFF;
  background: #1C1C1C;
  border-radius: 8px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.process-img {
  margin-right: 5px;
}

.process-img-act {
  filter: brightness(0) invert(1);
  margin-right: 5px;
}

.hand-cursor {
  cursor: pointer;
}

.banner-button-invert:hover {
  background-color: #30122A;
  cursor: pointer;
}

.banner-button:hover {
  background-color: #30122A;
  color: #FFFFFF;
  cursor: pointer;
}

.banner-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 10px;

  width: 140px;
  height: 48px;

  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  background-color: #914082;
  border-radius: 8px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.banner-button-invert>div {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.banner-button>div {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.banner-card {
  height: 650px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}

.banner-info-layer {
  height: 600px;
  padding-top: 175px;
}

.banner-img-layer {
  height: 650px;
  width: 650px;
}

.about-banner-card {
  height: 500px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}

.about-banner-info-layer {
  height: 500px;
  padding-top: 207px;
}

.about-banner-img-layer {
  height: 500px;
  width: 673px;
}

.impact-banner-card {
  height: 526px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}

.impact-banner-info-layer {
  width: 600px;
  height: 526px;
  padding-top: 207px;
}

.impact-banner-img-layer {
  height: 526px;
  width: 554px;
}

.home-about {
  background-color: "#1C1C1C" !important;
  width: "100%";
  display: flex;
}

.home-about div {
  flex: 1;
}

.home-about-holder {
  background: linear-gradient(to right, #914082, 0%, #914082 50%, #efefef 50%, #efefef 100%);
}

.text-center-aligned {
  text-align: center;
}

.banner-button-large {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 10px;

  width: 250px;
  height: 48px;

  color: #914082;
  border: 1px solid #FFFFFF;
  background-color: #eeeef0;
  border-radius: 8px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
}

a {
  color: white !important;
  text-decoration: none !important;
  /* no underline */
}

.margin-about-us {
  /*width: 60%;*/
  margin-left: 300px;
}

.margin-about-us-title {
  width: 40%;
}

.mobile-padding {
  padding: 0;
}

.para {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.sub-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.02em;
}

.content-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

.logo-action-sub-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.02em;
}

.img-bold-font {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 84px;
  letter-spacing: -0.04em;
  color: #101010;
}

.img-sub-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1C1C1C;
}

.app-img-card {
  border-radius: 5px;
  background-color: #FFFFFF;
  height: 240px;
  border: 1px solid #dfdfdf;
  box-shadow: 1px 1px 1px #dfdfdf
}

.globe-guide {
  width: 530px;
  height: 500px;
  object-fit: contain;
  overflow: hidden;
}

.mob-media-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.kriya-product {
  display: flex;
  flex-direction: row;
}

.kriya-product-image {
  flex: 1;
}

.kriya-product-content {
  padding-top: 120px;
  flex: 1;
}

/*.product-key-list {
  padding-top: 20px;
}*/

.footer-content-name {
  flex: 1;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.foo-section {
  display: flex;
  flex-direction: row;
}

.foo-section-item {
  flex: 1 0 50%;
}

.flex-auto-sec {
  display: flex;
  flex-direction: row;
}

.service-card-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  padding: 40px;
  gap: 20px;
  cursor: pointer;
}

.for-phel {
  background-color: #914082;
  flex: 1;
  color: #fff;
  padding: 80px;
  height: 318px;
}

@media only screen and (max-width: 845px) {

  .for-phel {
    padding: 40px;
  }

  .service-card-item {
    padding: 20px;
  }

  .flex-auto-sec {
    flex-direction: column;
  }

  .foo-section {
    flex-direction: column;
  }

  .foo-section-item {
    flex: 1 0 100%;
  }

  .footer-content-name {
    justify-content: left;
  }

  .product-list-item {
    flex: 1 0 80%;
  }

  .culture-card {
    margin-left: calc((100vw - 80px) /2);
  }
}

@media only screen and (max-width: 600px) {
  .culture-card {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1000px) {

  .remove-h-pad {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .contact-form {
    padding: 20px 0px;
  }

  .contact-form-content {
    padding: 0px;
  }

  .digital-prod-holder {
    flex-direction: column;
  }


  .blog-holder {
    flex-direction: column;
  }

  .service-holder {
    flex-direction: column;
    padding: 40px;
  }

  .form-holder {
    background: none;
    flex-direction: column;
  }


  .footer-content {
    flex-direction: column;
  }

  .kriya-product-key-list {
    display: flex;
    flex-direction: column;
  }

  .kriya-product {
    display: flex;
    flex-direction: column;
  }

  .home-product-molecule {
    flex-direction: column;
  }

  .home-product-split {
    display: flex;
    flex-direction: column;
  }

  .home-about-holder {
    margin-top: 40px;
    background: linear-gradient(to bottom, #914082, 0%, #914082 50%, #efefef 50%, #efefef 100%);
  }

  .banner-card {
    height: 1052px;
    flex-direction: column;
  }

  .about-banner-card {
    height: 800px;
    flex-direction: column;
  }

  .impact-banner-card {
    height: 1050px;
    flex-direction: column;
  }

  .banner-img-layer {
    width: calc(100vw - 60px);
  }

  .impact-banner-info-layer {
    width: calc(100vw - 60px);
  }

  .impact-banner-img-layer {
    width: calc(100vw - 60px);
  }

  .banner-img-layer>img {
    width: 90%;
    height: auto;
  }

  .impact-banner-info-layer {
    width: 80%;
  }

  .about-banner-img-layer {
    width: calc(100vw - 60px);
  }

  .about-banner-img-layer>img {
    width: 90%;
    height: auto;
  }

  .home-about {
    flex-direction: column;
    height: 60vh;
  }

  .footer-card {
    flex-direction: column;
  }

  .bg1-white {
    width: 80%;
    padding: 10px 40px;
    height: 80px;
    border-radius: 40px;
  }

  .logo-action-sub-title {
    font-size: 16px;
    line-height: 20px;
  }

  .logo-action-app-link {
    width: auto;
    height: 40px;
  }

  .mobile-padding {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .menu-font {
    padding: 26px 26px;
  }

  .footer-container {
    padding: 26px 26px;
  }

  .margin-about-us {
    margin-left: 0px;
  }

  .home-about-top-margin {
    padding-top: 40px;
    margin-top: 3rem;
  }

  .globe-guide {
    width: calc(100vw - 100px);
  }

  .globus {
    width: 80%;
  }

  .app-pad-sub {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .mob-media-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}